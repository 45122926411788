import * as React from 'react'
import { Header, Container, Segment, Icon, Image } from 'semantic-ui-react'
import { withLayout } from '../components/Layout'
import { Grid } from 'semantic-ui-react'
import { Typography } from '@material-ui/core'
const homeDrivewayImg = require('../../assets/home-driveway2.jpg')

const AboutPage = () => {
  return (
    <Container>
      <Segment vertical>
        <Header as="h1">
          <Icon name="info circle" />
          <Header.Content>About Us</Header.Content>
        </Header>
      </Segment>
      <br />
      <Segment vertical>
        <Grid stackable>
          <Header dividing={true} as="h2">
            <Header.Content>Company History</Header.Content>
          </Header>
          <Grid.Row>
            <Grid.Column width={10}>
              <Typography>
                Elite, established in the summer of 2010 by the Clark family,
                has maintained a strong reputation in the concrete industry.
                This success is attributed to our unwavering commitment to
                customer satisfaction and the delivery of high-quality work.
              </Typography>
              <Header>
                Our range of services for both residential and commercial
                settings include:
              </Header>
              <ul>
                <li>Concrete Removal</li>
                <li>Driveways</li>
                <li>Foundations</li>
                <li>Grading & Form Setting</li>
                <li>Patios</li>
                <li>Sidewalks</li>
                <li>Tear outs</li>
              </ul>
            </Grid.Column>
            <Grid.Column width={6}>
              <Image
                src={homeDrivewayImg}
                alt="image"
                size="large"
                rounded={true}
              />
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
    </Container>
  )
}

export default withLayout(AboutPage)
